export enum Perm {
	RoleList = "RoleList",
	RoleCreate = "RoleCreate",
	RoleRead = "RoleRead",
	RoleUpdate = "RoleUpdate",
	RoleArchive = "RoleArchive",
	RoleUnarchive = "RoleUnarchive",

	UserList = "UserList",
	UserCreate = "UserCreate",
	UserRead = "UserRead",
	UserUpdate = "UserUpdate",
	UserArchive = "UserArchive",
	UserUnarchive = "UserUnarchive",
	UserForceDisconnect = "UserForceDisconnect",

	ArtistList = "ArtistList",
	ArtistCreate = "ArtistCreate",
	ArtistRead = "ArtistRead",
	ArtistUpdate = "ArtistUpdate",
	ArtistArchive = "ArtistArchive",
	ArtistUnarchive = "ArtistUnarchive",

	AlbumList = "AlbumList",
	AlbumCreate = "AlbumCreate",
	AlbumRead = "AlbumRead",
	AlbumUpdate = "AlbumUpdate",
	AlbumArchive = "AlbumArchive",
	AlbumUnarchive = "AlbumUnarchive",

	PlaylistList = "PlaylistList",
	PlaylistCreate = "PlaylistCreate",
	PlaylistRead = "PlaylistRead",
	PlaylistUpdate = "PlaylistUpdate",
	PlaylistArchive = "PlaylistArchive",
	PlaylistUnarchive = "PlaylistUnarchive",

	SongList = "SongList",
	SongCreate = "SongCreate",
	SongRead = "SongRead",
	SongUpdate = "SongUpdate",
	SongArchive = "SongArchive",
	SongUnarchive = "SongUnarchive",

	PostList = "PostList",
	PostCreate = "PostCreate",
	PostRead = "PostRead",
	PostUpdate = "PostUpdate",
	PostArchive = "PostArchive",
	PostUnarchive = "PostUnarchive",

	BusinessList = "BusinessList",
	BusinessCreate = "BusinessCreate",
	BusinessRead = "BusinessRead",
	BusinessUpdate = "BusinessUpdate",
	BusinessArchive = "BusinessArchive",
	BusinessUnarchive = "BusinessUnarchive",

	VenueList = "VenueList",
	VenueCreate = "VenueCreate",
	VenueRead = "VenueRead",
	VenueUpdate = "VenueUpdate",
	VenueArchive = "VenueArchive",
	VenueUnarchive = "VenueUnarchive",

	RoyaltyList = "RoyaltyList",
	RoyaltyDefaultList = "RoyaltyDefaultList",
	RoyaltyCreate = "RoyaltyCreate",
	RoyaltyRead = "RoyaltyRead",
	RoyaltyUpdate = "RoyaltyUpdate",
	RoyaltyArchive = "RoyaltyArchive",
	RoyaltyUnarchive = "RoyaltyUnarchive",

	AdminPortal = "AdminPortal",
	ArtistPortal = "ArtistPortal",
	ImpersonateUser = "ImpersonateUser",
	UserActivityList = "UserActivityList",
}

export enum PermGroup {
	Role = "Role",
	User = "User",
	Artist = "Artist",
	Album = "Album",
	Song = "Song",
	Post = "Post",
	Other = "Other",
	Royalty = "Royalty",
}

export enum ObjectType {
	Blob = "Blob",
	Artist = "Artist",
	Role = "Role",
	User = "User",
	Album = "Album",
	Song = "Song",
	Venue = "Venue",
	Post = "Post",
	Royalty = "Royalty",
}

export enum FilterBy {
	All = "All",
	Active = "Active",
	Archived = "Archived",
}

export enum SortDir {
	Ascending = "Ascending",
	Descending = "Descending",
}

export enum AppType {
	Artist = "Artists",
	Venue = "Venues",
}
