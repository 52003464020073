enum HubKey {
	Welcome = "WELCOME",

	AuthLogin = "AUTH:LOGIN",
	AuthLoginToken = "AUTH:TOKEN",
	AuthLoginGoogle = "AUTH:GOOGLE",
	AuthLoginFacebook = "AUTH:FACEBOOK",
	AuthLogout = "AUTH:LOGOUT",
	AuthSuccess = "AUTH:SUCCESS",
	AuthRegister = "AUTH:REGISTER",
	AuthSendVerifyEmail = "AUTH:SEND_VERIFY_EMAIL",
	AuthVerifyAccount = "AUTH:VERIFY_ACCOUNT",
	AuthGenerateTFA = "AUTH:GENERATE:TFA",
	AuthCancelTFA = "AUTH:TFA:CANCEL",
	AuthTFAVerification = "AUTH:TFA:VERIFICATION",
	AuthTFARecoveryCodeGet = "AUTH:TFA:RECOVERY:CODE:GET",
	AuthTFARecoveryCodeSet = "AUTH:TFA:RECOVERY:CODE:SET",
	AuthTFARecovery = "AUTH:TFA:RECOVERY",
	AuthRegisterArtist = "AUTH:REGISTER:ARTIST",
	AuthRegisterVenue = "AUTH:REGISTER:VENUE",

	UserGet = "USER:GET",
	UserList = "USER:LIST",
	UserCreate = "USER:CREATE",
	UserUpdate = "USER:UPDATE",
	/** Tracks user changes */
	UserUpdated = "USER:UPDATED",
	UserArchive = "USER:ARCHIVE",
	UserUnarchive = "USER:UNARCHIVE",
	UserChangePassword = "USER:CHANGE_PASSWORD",
	UserOnlineStatus = "USER:ONLINE_STATUS",
	UserForceDisconnect = "USER:FORCE_DISCONNECT",
	UserForceDisconnected = "USER:FORCE_DISCONNECTED",

	RoleGet = "ROLE:GET",
	RoleList = "ROLE:LIST",
	RoleCreate = "ROLE:CREATE",
	RoleUpdate = "ROLE:UPDATE",
	RoleArchive = "ROLE:ARCHIVE",
	RoleUnarchive = "ROLE:UNARCHIVE",

	ArtistGet = "ARTIST:GET",
	ArtistList = "ARTIST:LIST",
	ArtistUserList = "ARTIST_USER:LIST",
	ArtistCreate = "ARTIST:CREATE",
	ArtistUpdate = "ARTIST:UPDATE",
	ArtistArchive = "ARTIST:ARCHIVE",
	ArtistUnarchive = "ARTIST:UNARCHIVE",

	BusinessGet = "BUSINESS:GET",
	BusinessList = "BUSINESS:LIST",
	BusinessCreate = "BUSINESS:CREATE",
	BusinessUpdate = "BUSINESS:UPDATE",
	BusinessArchive = "BUSINESS:ARCHIVE",
	BusinessUnarchive = "BUSINESS:UNARCHIVE",

	AlbumGet = "ALBUM:GET",
	AlbumList = "ALBUM:LIST",
	AlbumCreate = "ALBUM:CREATE",
	AlbumUpdate = "ALBUM:UPDATE",
	AlbumArchive = "ALBUM:ARCHIVE",
	AlbumUnarchive = "ALBUM:UNARCHIVE",

	SongGet = "SONG:GET",
	SongList = "SONG:LIST",
	SongCreate = "SONG:CREATE",
	SongUpdate = "SONG:UPDATE",
	SongArchive = "SONG:ARCHIVE",
	SongUnarchive = "SONG:UNARCHIVE",

	VenueGet = "VENUE:GET",
	VenueList = "VENUE:LIST",
	VenueCreate = "VENUE:CREATE",
	VenueUpdate = "VENUE:UPDATE",
	VenueArchive = "VENUE:ARCHIVE",
	VenueUnarchive = "VENUE:UNARCHIVE",

	PostGet = "POST:GET",
	PostList = "POST:LIST",
	PostCreate = "POST:CREATE",
	PostUpdate = "POST:UPDATE",
	PostArchive = "POST:ARCHIVE",
	PostUnarchive = "POST:UNARCHIVE",

	PlaylistGet = "PLAYLIST:GET",
	PlaylistList = "PLAYLIST:LIST",
	PlaylistCreate = "PLAYLIST:USER_CREATE",
	PlaylistUpdate = "PLAYLIST:UPDATE",
	PlaylistArchive = "PLAYLIST:ARCHIVE",
	PlaylistUnarchive = "PLAYLIST:UNARCHIVE",

	RoyaltyGet = "ROYALTY:GET",
	RoyaltyList = "ROYALTY:LIST",
	RoyaltySongList = "ROYALTY_SONG:LIST",
	RoyaltyCreate = "ROYALTY:CREATE",
	RoyaltySongCreate = "ROYALTY_SONG:CREATE",
	RoyaltyCharityCreate = "ROYALTY_CHARITY:CREATE",
	RoyaltyUpdate = "ROYALTY:UPDATE",
	RoyaltyArchive = "ROYALTY:ARCHIVE",
	RoyaltyUnarchive = "ROYALTY:UNARCHIVE",

	RoyaltyDefaultCreate = "ROYALTY_DEFAULT:CREATE",
	RoyaltyDefaultList = "ROYALTY_DEFAULT:LIST",
	RoyaltyDefaultDelete = "ROYALTY_DEFAULT:DELETE",

	CharityGet = "CHARITY:GET",
	CharityList = "CHARITY:LIST",
	CharityCreate = "CHARITY:CREATE",
	CharityUpdate = "CHARITY:UPDATE",
	CharityArchive = "CHARITY:ARCHIVE",
	CharityUnarchive = "CHARITY:UNARCHIVE",

	ImageList = "IMAGE:LIST",

	UserActivityList = "USER_ACTIVITY:LIST",
	UserActivityGet = "USER_ACTIVITY:GET",
	UserActivityCreate = "USER_ACTIVITY:CREATE",

	UserArtistCreate = "USER_ARTIST:CREATE",
}

export default HubKey
