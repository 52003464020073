import { useEffect, useState, useCallback } from "react"
import { createContainer } from "unstated-next"
import { User, Artist } from "../types/types"
import { useWebsocket } from "./socket"
import { PasswordLoginRequest, PasswordLoginResponse, TokenLoginRequest, TokenLoginResponse, VerifyAccountRequest, VerifyAccountResponse } from "../types/auth"
import HubKey from "../keys"
import { Perm } from "../types/enums"

export enum VerificationType {
	EmailVerification,
	ForgotPassword,
}

/**
 * A Container that handles Authorisation
 */
export const AuthContainer = createContainer(() => {
	const admin = process.env.REACT_APP_BUILD_TARGET === "ADMIN"
	const [user, setUser] = useState<User | null>(null)
	const [artist, setArtist] = useState<Artist | null>(null)
	const [authorised, setAuthorised] = useState(false)
	const [reconnecting, setReconnecting] = useState(false)
	const [loading, setLoading] = useState(true) // wait for loading current login state to complete first
	const [verifying, setVerifying] = useState(false)
	const [verifyCompleteType, setVerifyCompleteType] = useState<VerificationType>()
	const { state, send, subscribe, onReconnect } = useWebsocket()
	const [impersonatedUser, setImpersonatedUser] = useState<User>()

	/////////////////
	//  Functions  //
	/////////////////

	/**
	 * Logs user out by removing the stored login token and reloading the page.
	 */
	const logout = useCallback(() => {
		send(HubKey.AuthLogout).then(() => {
			localStorage.removeItem("token")
			window.location.reload()
		})
	}, [send])

	/**
	 * Logs a User in using their email and password.
	 */
	const loginPassword = useCallback(
		async (email: string, password: string) => {
			if (state !== WebSocket.OPEN) {
				return
			}
			const resp = await send<PasswordLoginResponse, PasswordLoginRequest>(HubKey.AuthLogin, {
				email,
				password,
				portal: admin ? "admin" : "artist",
			})
			if (!resp || !resp.user) {
				localStorage.clear()
				setUser(null)
				return
			}
			setArtist(resp.artist)
			setUser(resp.user)
			localStorage.setItem("token", resp.token)
			setAuthorised(true)
		},
		[send, state, admin],
	)

	/**
	 * Logs a User in using their saved login token.
	 *
	 * @param token login token usually from local storage
	 */
	const loginToken = useCallback(
		async (token: string) => {
			if (state !== WebSocket.OPEN) {
				return
			}
			setLoading(true)
			try {
				const resp = await send<TokenLoginResponse, TokenLoginRequest>(HubKey.AuthLoginToken, { token, portal: admin ? "admin" : "artist" })
				setArtist(resp.artist)
				setUser(resp.user)
				setAuthorised(true)
			} catch {
				localStorage.clear()
				setUser(null)
			} finally {
				setLoading(false)
				setReconnecting(false)
			}
		},
		[send, state, admin],
	)

	/**
	 * Logs a User in using a Google oauth token
	 *
	 * @param token Google token id
	 */
	const loginGoogle = useCallback(
		async (token: string): Promise<string | null> => {
			if (state !== WebSocket.OPEN) {
				return null
			}
			try {
				const resp = await send<PasswordLoginResponse, TokenLoginRequest>(HubKey.AuthLoginGoogle, { token, portal: admin ? "admin" : "artist" })
				setArtist(resp.artist)
				setUser(resp.user)
				if (!resp || !resp.user) {
					localStorage.clear()
					setUser(null)
					return null
				}
				setArtist(resp.artist)
				setUser(resp.user)
				localStorage.setItem("token", resp.token)
				setAuthorised(true)
			} catch (e) {
				localStorage.clear()
				setUser(null)
				return typeof e === "string" ? e : "Something went wrong, please try again."
			}
			return null
		},
		[send, state, admin],
	)

	/**
	 * Logs a User in using a Facebook oauth token
	 *
	 * @param token Google token id
	 */
	const loginFacebook = useCallback(
		async (token: string): Promise<string | null> => {
			if (state !== WebSocket.OPEN) {
				return null
			}
			try {
				const resp = await send<PasswordLoginResponse, TokenLoginRequest>(HubKey.AuthLoginFacebook, { token, portal: admin ? "admin" : "artist" })
				setArtist(resp.artist)
				setUser(resp.user)
				if (!resp || !resp.user) {
					localStorage.clear()
					setUser(null)
					return null
				}
				setArtist(resp.artist)
				setUser(resp.user)
				localStorage.setItem("token", resp.token)
				setAuthorised(true)
			} catch (e) {
				localStorage.clear()
				setUser(null)
				return typeof e === "string" ? e : "Something went wrong, please try again."
			}
			return null
		},
		[send, state, admin],
	)

	/**
	 * Verifies a User and takes them to the next page.
	 */
	const verify = useCallback(
		async (token: string, forgotPassword?: boolean) => {
			if (state !== WebSocket.OPEN) {
				return
			}
			setVerifying(true)
			const resp = await send<VerifyAccountResponse, VerifyAccountRequest>(HubKey.AuthVerifyAccount, {
				token,
				forgotPassword,
			})
			if (!resp || !resp.user) {
				localStorage.clear()
				setUser(null)
				setVerifying(false)
				return resp
			}
			setArtist(resp.artist)
			setUser(resp.user)
			setVerifying(false)
			setVerifyCompleteType(forgotPassword ? VerificationType.ForgotPassword : VerificationType.EmailVerification)
			localStorage.setItem("token", resp.token)
			setAuthorised(true)
			return resp
		},
		[send, state],
	)

	// TODO: Set impersonated users artist??
	/** Impersonate a User */
	const impersonateUser = async (user?: User) => {
		if (user === undefined || impersonatedUser !== undefined) setImpersonatedUser(undefined)
		if (!hasPermission(Perm.ImpersonateUser)) return

		if (!!user && !user.role?.permissions) {
			// Fetch user with full details
			const resp = await send<User>(HubKey.UserGet)
			if (!!resp) {
				setImpersonatedUser(resp)
			}
			return
		}

		setImpersonatedUser(user)
	}

	/** Checks if current user has a permission */
	const hasPermission = (perm: Perm) => {
		if (impersonatedUser) return impersonatedUser.role.permissions.includes(perm)

		if (!user || !user.role || !user.role.permissions) return false
		return user.role.permissions.includes(perm)
	}

	///////////////////
	//  Use Effects  //
	///////////////////

	// Effect: Login with saved login token when websocket is ready
	useEffect(() => {
		if (user || state === WebSocket.CLOSED) return

		const token = localStorage.getItem("token")
		if (token && token !== "") {
			loginToken(token)
		} else if (loading) {
			setLoading(false)
		}
	}, [loading, user, loginToken, state])

	// Effect: Relogin as User after establishing connection again
	useEffect(() => {
		if (state !== WebSocket.OPEN) {
			setAuthorised(false)
		} else if (!authorised && !reconnecting && !loading) {
			setReconnecting(true)
			const token = localStorage.getItem("token")
			if (token && token !== "") {
				;(async () => {
					await loginToken(token)
					onReconnect() // call queued outgoing messages
				})()
			}
		}
	}, [state, reconnecting, authorised, loginToken, onReconnect, loading])

	// Effect: Setup User Subscription after login
	const id = user?.id
	useEffect(() => {
		if (!id || !subscribe || !authorised) return
		return subscribe<User>(
			HubKey.UserUpdated,
			(u) => {
				if (u.id !== id) return
				setUser(u)
			},
			id,
		)
	}, [id, subscribe, authorised])

	// Log out if an admin force disconnected you
	useEffect(() => {
		if (!id || !subscribe || !authorised) return
		return subscribe<string>(HubKey.UserForceDisconnected, logout, id)
	}, [id, subscribe, logout, authorised])

	/////////////////
	//  Container  //
	/////////////////

	return {
		loginPassword,
		loginToken,
		loginGoogle,
		loginFacebook,
		logout,
		verify,
		hideVerifyComplete: () => setVerifyCompleteType(undefined),
		hasPermission,
		user: impersonatedUser || user,
		setUser,
		impersonateUser,
		isImpersonatingUser: impersonatedUser !== undefined,
		loading,
		verifying,
		verifyCompleteType,
		artist,
		setArtist,
	}
})

export const AuthProvider = AuthContainer.Provider
export const useAuth = AuthContainer.useContainer
