import React from "react"
import * as ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import { SENTRY_DSN } from "./config"
import { Themes } from "./containers"
import { LicenseInfo } from "@mui/x-data-grid-pro"

if (SENTRY_DSN) {
	Sentry.init({ dsn: SENTRY_DSN })
}

LicenseInfo.setLicenseKey("409a40db95ee58311cdef80dddd638fcT1JERVI6MzkzMTksRVhQSVJZPTE2Nzg0MTYyNTMwMDAsS0VZVkVSU0lPTj0x")

function importBuildTarget() {
	// DefinePlugin in webpack.config.js will substitute
	// process.env.REACT_APP_BUILD_TARGET with it's value at build time.
	// https://webpack.js.org/plugins/define-plugin/

	// TerserPlugin in webpack.config.js will eliminate dead code
	// ...if we make it easy enough (no maps or switches, etc).
	// https://webpack.js.org/plugins/terser-webpack-plugin/

	if (process.env.REACT_APP_BUILD_TARGET === "ADMIN") {
		document.title = "The Pack - Admin"
		return import("./AppAdmin/Admin")
	} else if (process.env.REACT_APP_BUILD_TARGET === "PUBLIC") {
		document.title = "The Pack"
		return import("./AppPublic/Public")
	} else if (process.env.REACT_APP_BUILD_TARGET === "VENUE") {
		document.title = "The Pack"
		return import("./AppVenue/Public")
	} else {
		return Promise.reject(new Error("No such build target: " + process.env.REACT_APP_BUILD_TARGET))
	}
}

// Import the entry point and render it's default export
importBuildTarget().then(({ default: Environment }) => {
	return ReactDOM.render(
		<React.StrictMode>
			<Themes.Provider>
				<Environment />
			</Themes.Provider>
		</React.StrictMode>,
		document.getElementById("root"),
	)
})
