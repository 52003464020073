import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { createContainer } from "unstated-next"
import { useDebounce } from "../hooks/useDebounce"
import HubKey from "../keys"
import { ServerConfig } from "../types/types"

/** API Endpoint Host */
export const API_ENDPOINT_HOSTNAME = `${process.env.REACT_APP_THEPACK_API_ENDPOINT_HOSTNAME ?? window.location.host}`

// makeid is used to generate a random sessionId for the websocket
export function makeid(length: number = 12): string {
	let result = ""
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * characters.length))
	}
	return result
}

const DateParse = () => {
	const reISO =
		/^([0-9]+)-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])[Tt]([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]|60)(\.[0-9]+)?(([Zz])|([+|-]([01][0-9]|2[0-3]):[0-5][0-9]))$/

	return function (key: string, value: any) {
		if (typeof value === "string") {
			const a = reISO.exec(value)
			if (a) return new Date(value)
		}
		return value
	}
}

const dp = DateParse()

function protocol() {
	return window.location.protocol.match(/^https/) ? "wss" : "ws"
}

enum SocketState {
	CONNECTING = WebSocket.CONNECTING,
	OPEN = WebSocket.OPEN,
	CLOSING = WebSocket.CLOSING,
	CLOSED = WebSocket.CLOSED,
}

type WSSendFn = <Y = any, X = any>(key: string, payload?: X) => Promise<Y>

interface WebSocketProperties {
	send: WSSendFn
	connect: () => Promise<undefined>
	state: SocketState
	subscribe: <T>(key: string, callback: (payload: T) => void, args?: any) => () => void
	onReconnect: () => void
	config: ServerConfig
}
type SubscribeCallback = (payload: any) => void

export interface Message<T> {
	sessionId?: string
	key: string
	payload: T
}

type WSCallback<T = any> = (data: T) => void

interface HubError {
	sessionId: string
	key: string
	message: string
}

const UseWebsocket = (): WebSocketProperties => {
	const [state, setState] = useState<SocketState>(SocketState.CLOSED)
	const callbacks = useRef<{ [key: string]: WSCallback }>({})
	const [outgoing, setOutgoing] = useDebounce<Message<any>[]>([], 50)
	const [config, setConfig] = useState<ServerConfig>({ fileHostURL: "" })

	const webSocket = useRef<WebSocket | null>(null)

	const send = useRef<WSSendFn>(function send<Y = any, X = any>(key: string, payload?: X): Promise<Y> {
		const sessionId = makeid()

		return new Promise(function (resolve, reject) {
			callbacks.current[sessionId] = (data: Message<Y> | HubError) => {
				if (data.key === "HUB:ERROR") {
					reject((data as HubError).message)
					return
				}
				const result = (data as Message<Y>).payload
				resolve(result)
			}

			setOutgoing((prev) => [
				...prev,
				{
					key,
					payload,
					sessionId,
				},
			])
		})
	})

	const subs = useRef<{ [key: string]: SubscribeCallback[] }>({})

	const subscribe = useMemo(() => {
		return <T>(key: string, callback: (payload: T) => void, args?: any) => {
			const sessionId = makeid()

			if (subs.current[sessionId]) subs.current[sessionId].push(callback)
			else subs.current[sessionId] = [callback]

			const setSubscribeState = (key: string, open: boolean, args?: any) => {
				setOutgoing((prev) => [
					...prev,
					{
						key: key + (open ? "" : ":UNSUBSCRIBE"),
						payload: open ? args : undefined,
						sessionId,
					},
				])
			}

			setSubscribeState(key, true, args)

			return () => {
				const i = subs.current[sessionId].indexOf(callback)
				if (i === -1) return
				subs.current[sessionId].splice(i, 1)

				setSubscribeState(key, false)
			}
		}
	}, [setOutgoing])

	const sendOutgoingMessages = useCallback(() => {
		if (outgoing.length === 0) return
		if (!webSocket.current) throw new Error("no websocket")
		webSocket.current.send(JSON.stringify(outgoing))
		setOutgoing([])
	}, [outgoing, setOutgoing])

	const setupWS = useMemo(
		() => (ws: WebSocket, onopen?: () => void) => {
			;(window as any).ws = ws

			ws.onopen = (e) => {
				// Use network sub menu to see payloads traveling between client and server
				// https://stackoverflow.com/a/5757171
				// console.info("WebSocket open.")
			}
			ws.onerror = (e) => {
				// Use network sub menu to see payloads traveling between client and server
				// https://stackoverflow.com/a/5757171
				// console.error("onerror", e)
				ws.close()
			}
			ws.onmessage = (message) => {
				const msgData = JSON.parse(message.data, dp)
				// Use network sub menu to see payloads traveling between client and server
				// https://stackoverflow.com/a/5757171

				if (msgData.key === HubKey.Welcome) {
					setConfig(msgData.payload.config)
					setReadyState()
					if (onopen) {
						onopen()
					}
				}
				if (msgData.sessionId) {
					const { [msgData.sessionId]: cb, ...withoutCb } = callbacks.current
					if (cb) {
						cb(msgData)
						callbacks.current = withoutCb
					}
				}
				if (subs.current[msgData.sessionId]) {
					for (const callback of subs.current[msgData.sessionId]) {
						callback(msgData.payload)
					}
				}
			}
			ws.onclose = (e) => {
				setReadyState()
			}
		},
		[],
	)

	const connect = useMemo(() => {
		return (): Promise<undefined> => {
			return new Promise(function (resolve, reject) {
				setState(WebSocket.CONNECTING)
				setTimeout(() => {
					webSocket.current = new WebSocket(`${protocol()}://${API_ENDPOINT_HOSTNAME}/api/ws`)
					setupWS(webSocket.current)
					resolve(undefined)
				}, 2000)
			})
		}
	}, [setupWS])

	const setReadyState = () => {
		if (!webSocket.current) {
			setState(WebSocket.CLOSED)
			return
		}
		setState(webSocket.current.readyState)
	}

	useEffect(() => {
		webSocket.current = new WebSocket(`${protocol()}://${API_ENDPOINT_HOSTNAME}/api/ws`)
		setupWS(webSocket.current)

		return () => {
			if (webSocket.current) webSocket.current.close()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (webSocket.current) sendOutgoingMessages()
	}, [webSocket, sendOutgoingMessages])

	return {
		send: send.current,
		state,
		connect,
		subscribe,
		onReconnect: sendOutgoingMessages,
		config,
	}
}

const WebsocketContainer = createContainer(UseWebsocket)
export const SocketProvider = WebsocketContainer.Provider
export const useWebsocket = WebsocketContainer.useContainer

export default WebsocketContainer
